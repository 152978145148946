<script>
import ResumeGridView from "./components/ResumeGridView";
export default {
  components: {
    ResumeGridView,
  },
  beforeMount() {
    if (this.$store.getters.getOperadorLevel >= 5) {
      this.$router.replace("/pdv");
    }
  },
  data: () => ({
    dataTableHeadersAgencies: [
      {
        text: "Agência",
        value: "AGENCIA",
        sortable: false,
      },
      {
        text: "Quantidade",
        value: "QUANTIDADE",
        sortable: false,
      },
      {
        text: "Valor",
        value: "VALOR",
        sortable: false,
      },
    ],
    dataTableHeadersDrivers: [
      {
        text: "Motorista",
        value: "MOTORISTA",
        sortable: false,
      },
      {
        text: "Quantidade",
        value: "QUANTIDADE",
        sortable: false,
      },
      {
        text: "Valor",
        value: "VALOR",
        sortable: false,
      },
    ],
    dataTableHeadersOthersTypesSales: [
      {
        text: "Cliente",
        value: "CLIENTE",
        sortable: false,
      },
      {
        text: "Quantidade",
        value: "QUANTIDADE",
        sortable: false,
      },
      {
        text: "Valor",
        value: "VALOR",
        sortable: false,
      },
    ],
    dataTableHeadersPacksByAgencies: [
      {
        text: "Agência",
        value: "AGENCIA",
        sortable: false,
      },
      {
        text: "Quantidade",
        value: "QUANTIDADE",
        sortable: false,
      },
      {
        text: "Valor",
        value: "VALOR",
        sortable: false,
      },
    ],
    dataTableHeadersPacksByDrivers: [
      {
        text: "Motorista",
        value: "MOTORISTA",
        sortable: false,
      },
      {
        text: "Quantidade",
        value: "QUANTIDADE",
        sortable: false,
      },
      {
        text: "Valor",
        value: "VALOR",
        sortable: false,
      },
    ],
  }),
};
</script>
