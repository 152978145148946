<script>
import DatePicker from "../../../../components/Calendars";
export default {
  components: {
    DatePicker,
  },
  props: ["title", "headers", "requestPath"],
  data: () => ({
    searchDateStart: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    searchDateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    data: [],
    loading: false,
  }),
  methods: {
    async getDashboardRequest() {
      if (this.$store.getters.getOperadorLevel >= 5) {
        this.$router.replace("/pdv");
      }
      // this.loading = true;
      const response = await this.$http.post(`${this.requestPath}`, {
        dateStart: this.searchDateStart,
        dateEnd: this.searchDateEnd,
      });
      this.data = response.data;
      this.loading = false;
    },
  },
  mounted() {
    this.getDashboardRequest();
    // setInterval(() => {
    //   this.getDashboardRequest();
    // }, 10000);
  },
};
</script>
